import type { LoaderFunction, LinksFunction } from 'remix';
import { authenticator } from '~/server/auth.server';
import Login from './login';
import { links as logoLinks } from '~/components/card/LogoCard';

export const loader: LoaderFunction = async ({ request }) => {
    const user = await authenticator().isAuthenticated(request, {
        successRedirect: '/requests'
    });
    return user;
};

export const links: LinksFunction = () => {
    return [...logoLinks()];
};

export default function Index() {
    return <Login />;
}
